/**
 * App Header
 */
import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import headerlogo from "../../../src/assets/img/profiles/OZHRMS.svg";
import { useNavigate } from "react-router-dom";
import clock_in from "../../assets/img/Polygon5.png";
import Select from "react-select";
import home_purple from "../../assets/img/profiles/home-pur.svg";
import outdoor_purple from "../../assets/img/profiles/outdoor-pur.svg";
import office_purple from "../../assets/img/profiles/offie-pur.svg";
import home_white from "../../assets/img/profiles/home-whi.svg";
import outdoor_white from "../../assets/img/profiles/outdoor-whi.svg";
import office_white from "../../assets/img/profiles/offie-whi.svg";
import stop from "../../assets/img/profiles/Stop.svg";
import sad from "../../assets/img/profiles/sad-icon.svg";
import confuse from "../../assets/img/profiles/confused-color-icon.svg";
import strange from "../../assets/img/profiles/strange-icon.svg";
import bright from "../../assets/img/profiles/bright-icon.svg";
import smile from "../../assets/img/profiles/smile-icon.svg";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";
import pauseIcon from "../../assets/img/profiles/pause-bl.svg";
import laugh from "../../assets/img/profiles/laugh-icon.svg";
import pause from "../../assets/img/profiles/pause.svg";
import one from "../../assets/img/profiles/1.svg";
import two from "../../assets/img/profiles/2.svg";
import three from "../../assets/img/profiles/3.svg";
import four from "../../assets/img/profiles/4.svg";
import five from "../../assets/img/profiles/5.svg";
import six from "../../assets/img/profiles/6.svg";
import desig from "../../assets/img/profiles/desig.svg";
import stopblack from "../../assets/img/profiles/Polygon6.svg";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useMediaQuery } from "@material-ui/core";
import Theme from "../../modelbox/theme";
import userimg from "../../assets/img/user.jpg";
import emoji from "../../assets/img/profiles/smileemoji.png";
import { TextField } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import ScrollToBottom from "react-scroll-to-bottom";
import Swal from "sweetalert2";
// import {
//   headerlogo,
//   lnEnglish,
//   lnFrench,
//   lnSpanish,
//   lnGerman,
//   Avatar_02,
//   Avatar_03,
//   Avatar_05,
//   Avatar_06,
//   Avatar_08,
//   Avatar_09,
//   Avatar_13,
//   Avatar_17,
//   Avatar_21,
// } from "../../Entryfile/imagepath";
import Avatar_02 from "../../assets/img/profiles/avatar-01.jpg";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Themes from "../../modelbox/themes";
import { color } from "@mui/system";
import { SBox, SFab } from "./style";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Modal,
} from "@mui/material";
import { UserContext } from "../../App";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "500px",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  textAlign: "center",
  maxWidth: 500,
  minWidth: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

const Header = (props) => {
  const [data, setData] = useState([]);
  const [openmodal, setOpenmodal] = useState(false);
  const [openmodal1, setOpenmodal1] = useState(false);
  const handleModalOpen = () => setOpenmodal(true);
  const handleModalOpen1 = () => setOpenmodal1(true);
  const handleModalClose = () => setOpenmodal(false);
  const handleModalClose1 = () => setOpenmodal1(false);
  const [show, setShow] = useState(false);
  const [colorChange, setColorchange] = useState(false);
  const [dateState, setDateState] = useState(new Date());
  const [showClock, setShowClock] = useState(false);
  const [current, setCurrent] = useState(`00:00`);
  const [workTime, setWorkTime] = useState(`00:00:00`);
  const [clockoutStat, setClockoutStat] = useState(false);
  const [workMode, setWorkMode] = useState(2);
  const [geolocation, setGeolocation] = useState({
    latitude: null,
    longitude: null,
  });
  // const [latitude, setLatitude] = useState(0);
  // const [longitude, setLongitude] = useState(0);
  const navigate = useNavigate();
  const [breakTime, setBreakTime] = useState(false);
  const [breakq, setBreakq] = useState(0);
  const [clockinStat, setClockinStat] = useState(
    sessionStorage.getItem("clockin_status")
  );
  const [showNot, setShowNot] = useState(false);
  const [mood, setMood] = useState("");
  const [moodindex, setMoodindex] = useState(0);
  const [breakStat, setBreakStat] = useState(`00:00:00`);
  const clockin_status = JSON.parse(sessionStorage.getItem("clockin_status"));
  const disable_clockin = JSON.parse(sessionStorage.getItem("disable_clockin"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const role = JSON.parse(sessionStorage.getItem("role"));
  const open = Boolean(anchorEl);
  const [noteNo, setNoteNo] = useState(0);
  const [noteNo1, setNoteNo1] = useState(0);
  const [open1, setOpen1] = useState(false);
  const [SurveyRes, setSurveyRes] = useState({});
  const adminActive = JSON.parse(sessionStorage.getItem("adminActive"));
  const previousValue = useRef(null);
  previousValue.current = noteNo;
  const location = useLocation();
  const [surveyQ, setSurveyQ] = useState([]);
  const user = useContext(UserContext);
  //console.log(user);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDesignation = (value) => {
    // e.preventDefault();
    console.log("called");
    sessionStorage.setItem("designation", value);
    setDesignation(sessionStorage.getItem("designation"));
  };

  const [designations, setDesignations] = useState(
    JSON.parse(sessionStorage.getItem("designations"))
  );
  const [designation, setDesignation] = useState(
    sessionStorage.getItem("designation")
  );

  const [notify, setNotify] = useState([
    { date_time: "", empid: "", id: "", messsage: "" },
  ]);
  const [notify1, setNotify1] = useState([
    { date_time: "", empid: "", id: "", messsage: "" },
  ]);
  const previousNotify = useRef(notify);
  const happyZone = JSON.parse(sessionStorage.getItem("happyZone"));
  // Auto Logout
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  const Wfh = sessionStorage.getItem("wfh");
  const matchesxlexact = useMediaQuery("(max-width:991.9px)");
  const matchesxsexact = useMediaQuery("(max-width:430px)");

  const GetHolidays = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/holidays_list/`,
        {
          params: {
            empid: sessionStorage.getItem("emp_id"),
            comp_id: sessionStorage.getItem("coid"),
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // const captureLocation = () => {
  //   geolocation.getCurrentPosition((error, position) => {
  //     if (error) {
  //       console.log("Error getting location:", error.message);
  //     } else {
  //       const { latitude, longitude } = position.coords;
  //       console.log("Latitude:", latitude);
  //       console.log("Longitude:", longitude);
  //       // Do something with the captured location data
  //     }
  //   });
  // };

  const requestLocationPermission = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          window.latitude = position.coords.latitude;
          window.longitude = position.coords.longitude;
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            console.log("Please grant location permission to continue.");
          } else {
            console.error("Error getting user location:", error);
          }
        },
        { timeout: 10000 }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    GetHolidays();
    requestLocationPermission();
  }, []);

  let timer;

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, 604800000); // 10000ms = 10secs. You can change the time.
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    sessionStorage.clear();
    window.location.pathname = "/";
  };

  ///////////////////////////

  // console.log(clockin_status);

  const useStyles = makeStyles((theme) => ({
    customHoverFocus: {
      "&:hover, &.Mui-focusVisible": { backgroundColor: "yellow" },
    },
    button: {
      backgroundColor: "green",
      color: theme.palette.primary.main,
      "&:hover": {
        "& .MuiSvgIcon-root": {
          color: "red",
        },
      },
    },
    buttonIcon: {
      color: theme.palette.common.white,
    },
  }));

  const classes = useStyles();

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };
  const onMenuClik = () => {
    props.onMenuClick();
  };

  let pathname = window.location.pathname;

  //////
  const date = new Date(sessionStorage.getItem("clockin_time"));
  // console.log(date)
  const break_t = new Date(sessionStorage.getItem("break_hours"));
  // console.log(break_t)
  // console.log(date.getTime());

  const handleLogout = () => {
    // caches.keys().then((names) => {
    //   names.forEach((name) => {
    //     caches.delete(name);
    //   });
    // });
    sessionStorage.removeItem("access_token");
    // sessionStorage.removeItem("refresh_token");
    // sessionStorage.removeItem("user");
    // sessionStorage.removeItem("emp_id");
    // sessionStorage.removeItem("clockin_status");
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
    window.location.reload(true);
  };
  const workConst = [
    {
      mode: "Home",
    },
    {
      mode: "Outdoor",
    },
    {
      mode: " In Office",
    },
  ];
  // var date =  Date.now();
  // var current_time = date.getHours() + ":" + date.getMinutes();

  useEffect(() => {
    setInterval(() => setDateState(new Date()), 1000);
  }, []);

  var hours =
    // dateState.getHours() > 12
    // ? dateState.getHours() - 12
    dateState.getHours();
  // var am_pm = dateState.getHours() >= 12 ? "PM" : "AM";
  hours = hours < 10 ? "0" + hours : hours;
  var minutes =
    dateState.getMinutes() < 10
      ? "0" + dateState.getMinutes()
      : dateState.getMinutes();
  var seconds =
    dateState.getSeconds() < 10
      ? "0" + dateState.getSeconds()
      : dateState.getSeconds();
  // time = hours + ":" + minutes + ":" + seconds + " " + am_pm;
  // var lblTime = document.getElementById("lblTime");
  // lblTime.innerHTML = time;

  // console.log(
  //   dateState.toLocaleString("en-US", {
  //     hour: "numeric",
  //     minute: "numeric",
  //     hour12: true,
  //   })
  // );

  // console.log(time)
  // var secDiff = time / 1000; //in s
  // var minDiff = time / 60 / 1000; //in minutes
  // var hDiff = time / 3600 / 1000; //in hours
  // var hour = Math.floor(hDiff)
  // console.log(hour)
  // var min = minDiff - 60 * Math.floor(hDiff)
  // var sec= secDiff-3600*min* hour
  // console.log(`${hour}:${min}:${sec}`);

  const handleNotification = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/notification/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
          toggle: "24hr",
        },
      })
      .then((res) => {
        setNotify(res.data);
        if (noteNo === 0) {
          setNoteNo(res.data.length - previousValue.current);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNotification1 = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/notification/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
          toggle: "24hr",
        },
      })
      .then((res) => {
        setNotify1(res.data.reverse().map((v) => v));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleNotification1();
    setInterval(() => {
      handleNotification();
    }, 500000);
  }, []);
  //console.log(noteNo);
  //console.log(noteNo1);

  const employeeDetails = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/employee_full_details/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        sessionStorage.setItem("profile_pic", res.data.profile_pic.links);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    employeeDetails();
  }, []);

  useEffect(() => {
    previousNotify.current = notify;
  }, [notify]);

  useEffect(() => {
    if (show === false) {
      const interval = setInterval(() => {
        handleNotification();
      }, 20000);
      return () => clearInterval(interval);
    }
  }, [show]);

  var elements = document.getElementsByClassName("main-wrapper");
  const scroller = document.querySelector(".main-wrapper");
  const break1 = JSON.parse(sessionStorage.getItem("breaking_hours1"));
  useEffect(() => {
    if (clockin_status === true && breakTime === false) {
      const time = dateState.getTime() - date.getTime() - break1;

      var milliseconds = Math.floor((time % 1000) / 100),
        seconds = Math.floor((time / 1000) % 60),
        minutes = Math.floor((time / (1000 * 60)) % 60),
        hours = Math.floor((time / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setWorkTime(hours + ":" + minutes + ":" + seconds);
      sessionStorage.setItem(
        "clockin_hours",
        hours + ":" + minutes + ":" + seconds
      );
      // console.log(hours + ":" + minutes + ":" + seconds);
    } else {
      setWorkTime(sessionStorage.getItem("clockin_hours"));
    }
  }, [dateState]);

  useEffect(() => {
    if (clockin_status === true && breakTime === true) {
      const time = dateState.getTime() - break_t.getTime() + break1;

      var milliseconds = Math.floor((time % 1000) / 100),
        seconds = Math.floor((time / 1000) % 60),
        minutes = Math.floor((time / (1000 * 60)) % 60),
        hours = Math.floor((time / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setBreakStat(hours + ":" + minutes + ":" + seconds);
      sessionStorage.setItem(
        "breaking_hours",
        hours + ":" + minutes + ":" + seconds
      );
      setBreakq(time);
      // console.log(hours + ":" + minutes + ":" + seconds);
    } else {
      setBreakStat(sessionStorage.getItem("breaking_hours"));
    }
  }, [dateState]);

  const changeNavbarColor = () => {
    // console.log("yess");
    // console.log(scroller.scrollTop);
    if (scroller.scrollTop >= 25) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
    // setColorchange(true)
  };
  for (var i = 0; i < elements.length; i++) {
    elements[i].addEventListener(
      "scroll",
      function () {
        changeNavbarColor();
      },
      false
    );
  }
  const token = sessionStorage.getItem("access_token");

  const handleClockIn = async () => {
    requestLocationPermission();
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/attendance/`,
        {
          empid: sessionStorage.getItem("emp_id"),
          location:
            workMode === 0 ? "Home" : workMode === 1 ? "Outdoor" : "Office",
          longitude: window.longitude ? window.longitude : null,
          latitude: window.latitude ? window.latitude : null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        sessionStorage.setItem("clockin_status", res.data.clockin_status);
        sessionStorage.setItem("clockin_time", res.data.clockin_time);
        setShowClock(false);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err.response.data);
        if (err.response.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err.response.data.message}!`,
          });
        } else {
        }
      });
  };

  const handleClockOut = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/attendance/`,
        {
          empid: sessionStorage.getItem("emp_id"),
          mood: mood,
          longitude: window.longitude ? window.longitude : null,
          latitude: window.latitude ? window.latitude : null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        sessionStorage.setItem("clockin_status", res.data.clockin_status);
        sessionStorage.setItem("disable_clockin", res.data.disable_clockin);
        setShowClock(false);
        setClockoutStat(false);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSwitch = () => {
    sessionStorage.setItem("adminActive", !adminActive);
    user.happyBirthday();
    if (adminActive) {
      navigate("/dashboard");
    } else {
      navigate("/reports");
    }
  };

  const handleBreaks = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/breaks/`,
        {
          empid: sessionStorage.getItem("emp_id"),
          location:
            workMode === 0 ? "Home" : workMode === 1 ? "Outdoor" : "Office",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        //sessionStorage.setItem("clockin_status", res.data.clockin_status);
        //sessionStorage.setItem("clockin_time", res.data.clockin_time);
        //setShowClock(false);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetSurvey = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/survey/`, {
        params: {
          toggle: "e",
          empid: JSON.parse(sessionStorage.getItem("emp_id")),
        },
      })
      .then((res) => {
        setSurveyQ(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleGetSurvey();
  }, []);

  const handlePostSurvey = (value) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/survey/`, value)
      .then((res) => {
        // alert("Survey Form Submitted");
        handleGetSurvey();
      })
      .catch((err) => {
        alert("something went wrong");
      });
  };

  return (
    <div
      className={
        colorChange ? "header-back header active" : " header-back header"
      }
      style={{
        right: "0px",
        // backgroundColor: pathname === "/happyzone" && "#212121",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="header-left">
          <div
            style={
              !matchesxsexact
                ? {
                    display: "flex",
                    backgroundColor: "white",
                    flexDirection: "column",
                    marginTop: "5px",
                    marginLeft: "5px",
                    padding: "5px",
                    width: "130px",
                    borderRadius: "10px",
                  }
                : {
                    display: "flex",
                    backgroundColor: "white",
                    flexDirection: "column",
                    marginTop: "5px",
                    marginLeft: "5px",
                    padding: "5px",
                    width: "100px",
                    borderRadius: "10px",
                  }
            }
            // style={
            //   !matchesxsexact
            //     ? {
            //         display: "flex",
            //         backgroundColor: "white",
            //         flexDirection: "column",
            //         marginTop: "5px",
            //         marginLeft: "5px",
            //         padding: "5px",
            //         width: "130px",
            //         borderRadius: "10px",
            //       }
            //     : {
            //         display: "flex",
            //         backgroundColor: "white",
            //         flexDirection: "column",
            //         marginTop: "5px",
            //         marginLeft: "5px",
            //         padding: "5px",
            //         width: "100px",
            //         borderRadius: "10px",
            //       }
            // }
          >
            {/* <Link className="logo"> */}
            {!happyZone && (
              <img
                src={sessionStorage.getItem("company_logo")}
                style={
                  !matchesxsexact
                    ? { width: "120px", height: "40px", maxHeight: "40px" }
                    : { width: "90px", height: "40px", maxHeight: "40px" }
                }
                // style={
                //   !matchesxsexact
                //     ? { width: "120px", height: "40px", maxHeight: "40px" }
                //     : { width: "90px", height: "40px", maxHeight: "40px" }
                // }
                alt=""
              />
            )}
            {happyZone && !adminActive && (
              <Link
                to="/dashboard"
                onClick={() => sessionStorage.setItem("happyZone", false)}
              >
                <img
                  src={sessionStorage.getItem("company_logo")}
                  style={
                    !matchesxsexact
                      ? { width: "120px", height: "40px", maxHeight: "40px" }
                      : { width: "90px", height: "40px", maxHeight: "40px" }
                  }
                  // style={
                  //   !matchesxsexact
                  //     ? { width: "120px", height: "40px", maxHeight: "40px" }
                  //     : { width: "90px", height: "40px", maxHeight: "40px" }
                  // }
                  alt=""
                />
              </Link>
            )}
            {happyZone && adminActive && (
              <Link
                to="/admin-attendance"
                onClick={() => sessionStorage.setItem("happyZone", false)}
              >
                <img
                  src={sessionStorage.getItem("company_logo")}
                  style={
                    !matchesxsexact
                      ? { width: "120px", height: "40px", maxHeight: "40px" }
                      : { width: "90px", height: "40px", maxHeight: "40px" }
                  }
                  // style={
                  //   !matchesxsexact
                  //     ? { width: "120px", height: "40px", maxHeight: "40px" }
                  //     : { width: "90px", height: "40px", maxHeight: "40px" }
                  // }
                  alt=""
                />
              </Link>
            )}

            {/* </Link> */}
          </div>
        </div>

        <a
          id="mobile_btn"
          className="mobile_btn"
          href="#"
          onClick={() => onMenuClik()}
        >
          <i className="fa fa-bars" />
        </a>
        {/* Header Menu */}
        <button
          className={colorChange ? "clock-in active" : "clock-in"}
          style={
            matchesxsexact
              ? { background: "transparent", marginLeft: "160px" }
              : { background: "transparent" }
          }
          onClick={() => {
            setShowClock(!showClock);
            setOpen1(false);
          }}
        >
          <img
            src={
              colorChange
                ? clockin_status
                  ? pause
                  : clock_in //stopblack
                : clockin_status
                ? pause //pauseIcon
                : clock_in
            }
          />{" "}
          <span
            className={colorChange ? "page-title active" : "page-title"}
            style={{ fontSize: "17px", fontWeight: "400", marginTop: "7px" }}
          >
            {clockin_status
              ? `Working`
              : !disable_clockin
              ? ` Clock In`
              : `Clocked Out`}
          </span>{" "}
          <span
            className={colorChange ? "page-title active" : "page-title"}
            style={{ fontSize: "35px", marginTop: "7px" }}
          >
            {/* {dateState.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              // hour12: true,
            })} */}
            {hours + ":" + minutes}
            {/* &nbsp;<sup>{am_pm}</sup> */}
          </span>
        </button>

        {/* {disable_clockin?
               ( 
                <div className="clock_div">
                  <div className="work_mode_div">
                    <button className="regular">!ClockedOut</button>
                  </div>
                </div>    ) : ( 
        <> */}

        {showClock && disable_clockin && (
          <div className="clock_div">
            {/* <p
              style={{ float: "right", marginTop: "-15px", cursor: "pointer" }}
              onClick={() => setShowClock(false)}
            >
              X
            </p> */}
            <button
              style={{
                float: "right",
                marginTop: "-15px",
                cursor: "pointer",
                border: "none",
                background: "none",
                marginRight: "-20px",
              }}
              type="button"
              onClick={() => setShowClock(false)}
            >
              <img src={closeIcon} />
            </button>
            <h4 style={{ padding: "45px 10px 0px 18px" }}>
              <b>You already clocked out of the day.</b>
            </h4>
            <br />
            {/* <div className="work_mode_div"></div> */}
            <div className="regular_div">
              {!happyZone && (
                <button
                  className="regular"
                  onClick={() => navigate("/attendance-grid")}
                >
                  Regularise
                </button>
              )}
            </div>
          </div>
        )}
        {!clockin_status && showClock && !disable_clockin && (
          <div className="clock_div">
            <button
              style={{
                float: "right",
                marginTop: "-15px",
                cursor: "pointer",
                border: "none",
                background: "none",
                marginRight: "-20px",
              }}
              type="button"
              onClick={() => setShowClock(false)}
            >
              <img src={closeIcon} />
            </button>
            <h4>Working From</h4>
            <br />
            <div className="work_mode_div">
              {workConst.map((ele, index) => (
                <button
                  disabled={!Wfh && ele.mode === "Home"}
                  className="work_mode"
                  style={{
                    background: workMode === index ? "#973FCF" : "white",
                    color: workMode === index ? "white" : "#973FCF",
                  }}
                  onClick={() => setWorkMode(index)}
                >
                  <img
                    src={
                      workMode === index
                        ? ele.mode === "Home"
                          ? home_white
                          : ele.mode === "Outdoor"
                          ? outdoor_white
                          : office_white
                        : ele.mode === "Home"
                        ? home_purple
                        : ele.mode === "Outdoor"
                        ? outdoor_purple
                        : office_purple
                    }
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "2px",
                      marginBottom: "2px",
                    }}
                  />{" "}
                  {ele.mode}
                </button>
              ))}
            </div>
            <div className="regular_div">
              <button
                className="regular"
                onClick={() => navigate("/attendance-grid")}
              >
                Regularise
              </button>

              <button className="clock-in-btn" onClick={() => handleClockIn()}>
                {" "}
                <img src={clock_in} /> Clock In
              </button>
            </div>
          </div>
        )}
        {clockin_status && showClock && !disable_clockin && (
          <div className="clock_div">
            <button
              style={{
                float: "right",
                marginTop: "-15px",
                cursor: "pointer",
                border: "none",
                background: "none",
                marginRight: "-20px",
              }}
              type="button"
              onClick={() => setShowClock(false)}
            >
              <img src={closeIcon} />
            </button>
            <h5
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              Working Day Duration
            </h5>

            <div className="work_time_div">
              <div className="current">
                <p className="current_time">Current Time</p>
                <p>{workTime}</p>
              </div>
              <div className="break_div">
                {" "}
                <p className="break_time">Break Time</p>
                <p>{breakStat ? breakStat : "00:00:00"}</p>
              </div>
            </div>
            <div className="regular_div">
              {!breakTime ? (
                <button
                  className="break"
                  onClick={() => {
                    setBreakTime(!breakTime);
                    handleBreaks();
                    sessionStorage.setItem("break_hours", new Date());
                    // sessionStorage.setItem("breaking_hours1", breakq);
                  }}
                >
                  {" "}
                  <img src={clock_in} /> Break
                </button>
              ) : (
                <button
                  className="break"
                  onClick={() => {
                    setBreakTime(!breakTime);
                    handleBreaks();
                    // sessionStorage.setItem("break_hours", new Date());
                    sessionStorage.setItem("breaking_hours1", breakq);
                  }}
                >
                  {" "}
                  <img src={pause} /> Break
                </button>
              )}
              <button
                className="clock_out"
                onClick={() => {
                  setShowClock(!showClock);
                  setClockoutStat(true);
                }}
              >
                {" "}
                <img src={stop} /> Clock Out
              </button>
            </div>
          </div>
        )}
        {!showClock && clockoutStat && !disable_clockin && (
          <div className="clock_div">
            <button
              style={{
                float: "right",
                marginTop: "-15px",
                cursor: "pointer",
                border: "none",
                background: "none",
                marginRight: "-20px",
              }}
              type="button"
              onClick={() => {
                setShowClock(false);
                setClockoutStat(false);
              }}
            >
              <img src={closeIcon} />
            </button>
            {/* <p
              style={{ float: "right", marginTop: "-15px", cursor: "pointer" }}
              onClick={() => {
                setShowClock(false);
                setClockoutStat(false);
              }}
            >
              X
            </p> */}
            <h5
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <b>How was your day at work ?</b>
            </h5>
            <br />
            {/* <div className="progress-div">
              {" "}
              <div
                className="progress_div_fill"
                style={{
                  width:
                    mood === "sad" ? "0%" : mood === "happy" ? "20%" : "0%",
                }}
              ></div>{" "}
            </div> */}
            <div
              // style={{ display: "flex", justifyContent: "space-between" }}
              className="smile-one"
            >
              <img
                src={mood === "Upset" ? sad : one}
                style={{ width: "35px", height: "35px" }}
                onClick={() => setMood("Upset")}
                className="mood-smile"
              />
              <img
                src={mood === "Disturbed" ? confuse : two}
                style={{ width: "35px", height: "35px", marginLeft: "20px" }}
                onClick={() => setMood("Disturbed")}
                className="mood-smile"
              />
              <img
                src={mood === "Irritated" ? strange : three}
                style={{ width: "35px", height: "35px", marginLeft: "20px" }}
                onClick={() => setMood("Irritated")}
                className="mood-smile"
              />
              <img
                src={mood === "Optimistic" ? bright : four}
                style={{ width: "35px", height: "35px", marginLeft: "20px" }}
                onClick={() => setMood("Optimistic")}
                className="mood-smile"
              />
              <img
                src={mood === "Cheerful" ? smile : five}
                style={{ width: "35px", height: "35px", marginLeft: "20px" }}
                onClick={() => setMood("Cheerful")}
                className="mood-smile"
              />
              <img
                src={mood === "Cackle" ? laugh : six}
                style={{ width: "35px", height: "35px", marginLeft: "20px" }}
                onClick={() => setMood("Cackle")}
                className="mood-smile"
              />
            </div>
            <br />

            <button
              className="clock_out1"
              onClick={() => {
                handleClockOut();
              }}
            >
              {" "}
              <img src={stop} /> Clock Out
            </button>
          </div>
        )}
        {/* </>)} */}

        <ul className="nav user-menu">
          {surveyQ.length !== 0 && (
            <button
              style={{
                color: "white",
                border: "none",
                height: "40px",
                padding: "6px 8px",
                marginTop: "8px",
                background: "#ed9d44",
                borderRadius: "8px",
              }}
              onClick={() => handleModalOpen1()}
            >
              Survey Form
            </button>
          )}
          &nbsp;&nbsp;
          {!happyZone &&
            (!matchesxlexact ? (
              <Link
                to="/welcomezone"
                onClick={() => sessionStorage.setItem("happyZone", true)}
                style={{ color: "white" }}
              >
                <button className="happy-zone">HAPPY ZONE</button>
              </Link>
            ) : null)}
          {happyZone && !adminActive && (
            <Link
              to="/dashboard"
              onClick={() => sessionStorage.setItem("happyZone", false)}
              style={{ color: "white" }}
            >
              <button className="happy-zone">HRMS DASHBOARD</button>
            </Link>
          )}
          {happyZone && adminActive && (
            <Link
              to="/admin-attendance"
              onClick={() => sessionStorage.setItem("happyZone", false)}
              style={{ color: "white" }}
            >
              <button className="happy-zone">HRMS DASHBOARD</button>
            </Link>
          )}
          {!happyZone && (
            <>
              {/* Multi designation dropdown */}
              {designations?.length > 1 && (
                <li className="nav-item dropdown">
                  <a
                    href="#"
                    className="dropdown-toggle nav-link"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={desig} />
                  </a>

                  <div className="dropdown-menu designations">
                    <div className="designation-content">
                      {designations?.map((ele, index) => {
                        return (
                          <button
                            onClick={() => handleDesignation(ele.label)}
                            style={{
                              color:
                                designation === ele.label ? "#fff" : "#666666",
                              background:
                                designation === ele.label ? "#3298DB" : "none",
                            }}
                          >
                            {ele.label}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </li>
              )}
              {/* <Link to="/welcomezone" style={{ color: "white" }}>
            <button className="happy-zone">HAPPY ZONE</button>
          </Link> */}
              <li className="nav-item dropdown">
                <a
                  href="#"
                  className="dropdown-toggle nav-link"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => {
                    setNoteNo(0);
                    setShow(true);
                    setOpen1(false);
                  }}
                >
                  <i
                    className="fa fa-bell-o"
                    style={
                      { color: "#FFFFFF" }
                      /*colorChange ? { color: "#FFFFFF" } : { color: "FFFFFF" }*/
                    }
                  />{" "}
                  {noteNo > 0 && (
                    <span className="badge badge-pill">{noteNo}</span>
                  )}
                </a>

                <div className="dropdown-menu notifications note ">
                  <div className="topnav-dropdown-header">
                    <span className="notification-title">Notifications</span>
                    {/* <a href="" className="clear-noti">
                  {" "}
                  Clear All{" "}
                </a> */}
                  </div>
                  <div className="noti-content">
                    <ul className="notification-list">
                      {notify.map((ele) => (
                        <li className="notification-message">
                          <Link
                            onClick={() =>
                              localStorage.setItem("minheight", "true")
                            }
                            to="/app/administrator/activities"
                          >
                            <div className="media">
                              <span className="avatar">
                                <img alt="" src={Avatar_02} />
                              </span>
                              <div className="media-body">
                                <p className="noti-details">
                                  <span className="noti-title">
                                    {ele.messsage}
                                  </span>
                                </p>
                                {/* <p className="noti-time">
                              <span className="notification-time">
                                4 mins ago
                              </span>
                            </p> */}
                              </div>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* <div className="topnav-dropdown-footer">
                <Link
                  onClick={() => localStorage.setItem("minheight", "true")}
                  to="/app/administrator/activities"
                >
                  View all Notifications
                </Link>
              </div> */}
                </div>
              </li>
              {/* <li className="nav-item dropdown has-arrow main-drop">
          <a
            href="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
          >
            <span className="user-img me-1">
            
              <span className="status online" />
            </span>
            <span>Admin</span>
          </a>
          <div className="dropdown-menu">
            <Link className="dropdown-item" to="/employee-profile">
              My Profile
            </Link>
            <Link className="dropdown-item" to="/settings/companysetting">
              Settings
            </Link>
            <Link className="dropdown-item" to="/login">
              Logout
            </Link>
          </div>
        </li> */}
              {/* <button className=" logout-btn" onClick={() => handleLogout()}>
            Logout
          </button> */}
              &nbsp; &nbsp; &nbsp;
              <button className="logout-btn">
                <Box>
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    color="white"
                    sx={{ ml: -2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    // className={classes.button}
                  >
                    <Avatar
                      sx={{
                        width: 25,
                        height: 25,
                        marginLeft: 1,
                        float: "left",
                      }}
                    >
                      <img
                        style={{ width: 25, height: 25 }}
                        src={
                          sessionStorage.getItem("profile_pic")
                            ? sessionStorage.getItem("profile_pic")
                            : userimg
                        }
                      />
                    </Avatar>
                    &nbsp; &nbsp;
                    <p
                      style={{
                        fontSize: "14px",
                        marginTop: "15px",
                        color: "white",
                        fontWeight: "500",
                      }}
                    >
                      {sessionStorage.getItem("name")?.split(" ")[0]}
                    </p>
                  </IconButton>
                </Box>
              </button>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {/* <MenuItem>
                  <Avatar /> Profile
                </MenuItem>
                <MenuItem>
                  <Avatar /> My account
                </MenuItem> 
                <Divider />*/}
                {role !== 0 && !adminActive && (
                  <MenuItem onClick={() => handleSwitch()}>
                    <ListItemIcon>
                      <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    Switch to Admin
                  </MenuItem>
                )}
                {role !== 0 && adminActive && (
                  <MenuItem onClick={() => handleSwitch()}>
                    <ListItemIcon>
                      <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    Switch to Employee
                  </MenuItem>
                )}
                <MenuItem onClick={handleModalOpen}>
                  <ListItemIcon>
                    <CalendarMonthIcon fontSize="small" />
                  </ListItemIcon>
                  Holiday List
                </MenuItem>
                {location.pathname === "/dashboard" ? (
                  <MenuItem
                    data-bs-toggle="modal"
                    data-bs-target="#change_theme"
                  >
                    <ListItemIcon>
                      <i className="fa fa-pencil m-l-3" />
                    </ListItemIcon>
                    Change Theme
                  </MenuItem>
                ) : null}
                {JSON.parse(sessionStorage.getItem("permissions")) &&
                  JSON.parse(sessionStorage.getItem("permissions"))?.Admin !==
                    "NoAccess" && (
                    <MenuItem onClick={() => navigate("/roles-permissions")}>
                      <ListItemIcon>
                        <Settings fontSize="small" />
                      </ListItemIcon>
                      Settings
                    </MenuItem>
                  )}
                <MenuItem onClick={() => handleLogout()}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
              {/* <button onClick={()=navigate(-1)}>back</button> */}
            </>
          )}
        </ul>
      </div>
      {/* /Header Menu */}
      {/* Mobile Menu */}
      {/* <div className="dropdown mobile-user-menu">
        <a
          href="#"
          className="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v" />
          
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <Link className="dropdown-item" to="/app/profile/employee-profile">
            My Profile
          </Link>
          <Link className="dropdown-item" to="/settings/companysetting">
            Settings
          </Link>
          <Link className="dropdown-item" to="/login">
            Logout
          </Link>
        </div> */}
      {/* </div> */}
      {/* /Mobile Menu */}
      <Modal
        open={openmodal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button
            style={{
              float: "right",
              marginTop: "-15px",
              cursor: "pointer",
              border: "none",
              background: "none",
              marginRight: "-20px",
            }}
            type="button"
            onClick={handleModalClose}
          >
            <img src={closeIcon} />
          </button>
          <h4>Holidays</h4>
          <hr style={{ height: "3px", color: "#393f5c" }} />

          <TableContainer>
            <Table
              sx={{ minWidth: 250, borderRadius: "5px" }}
              size="small"
              aria-label="a dense table"
            >
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ background: "#FFFFFF" }}
                    >
                      <Typography variant="body1">{row.name}</Typography>
                    </TableCell>

                    <TableCell
                      align="right"
                      sx={{ background: "#FFFFFF", textAlign: "left" }}
                    >
                      <Typography variant="body1">
                        {row.date?.split("-")?.reverse()?.join("-")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>

      <Modal
        open={openmodal1}
        onClose={handleModalClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button
            style={{
              float: "right",
              marginTop: "-15px",
              cursor: "pointer",
              border: "none",
              background: "none",
              marginRight: "-20px",
            }}
            type="button"
            onClick={handleModalClose1}
          >
            <img src={closeIcon} />
          </button>
          <b style={{ color: "#aa0000", fontSize: "20px" }}>Survey Form</b>
          <hr style={{ height: "2px", color: "#aa0000" }} />

          <TableContainer>
            <Table
              sx={{ minWidth: 200, borderRadius: "5px" }}
              size="small"
              aria-label="a dense table"
            >
              <TableBody>
                {surveyQ?.map((ele) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "left",
                      marginBottom: "8px",
                    }}
                  >
                    <b>{ele.name}</b>
                    {ele.question?.map((item) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "left",
                          marginBottom: "8px",
                        }}
                      >
                        <p style={{ color: "#004d00" }}>{item.question}</p>
                        {item.option?.map((e) => (
                          <div style={{ marginBottom: "5px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "left",
                                marginBottom: "5px",
                              }}
                            >
                              <input
                                type="radio"
                                style={{ cursor: "pointer" }}
                                id={e.id}
                                disabled={
                                  item.your_response.length === 0 ? false : true
                                }
                                name="fav_language"
                                // value="HTML"
                                checked={item.your_response.includes(e.id)}
                                // onChange={() =>
                                //   setSurveyRes({
                                //     questionid: item.id,
                                //     survey_id: item.survey_id,
                                //     optionid: e.id,
                                //     empid: sessionStorage.getItem("emp_id"),
                                //     toggle: "a",
                                //   })
                                // }
                                onChange={() =>
                                  handlePostSurvey({
                                    question_id: item.id,
                                    survey_id: item.survey_id,
                                    option_id: e.id,
                                    empid: sessionStorage.getItem("emp_id"),
                                    toggle: "a",
                                  })
                                }
                              />
                              <label htmlFor={e.id}>{e.option}</label>
                            </div>
                            {item?.your_response?.length !== 0 && (
                              <div className="progress">
                                <div
                                  className="progress-bar bg-success"
                                  role="progressbar"
                                  style={{
                                    width: e.percentage * 100,
                                  }}
                                  aria-valuenow={e.percentage * 100}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>

      <Themes
        handleChange={props.handleChange}
        backImg={props.backImg}
        setBackImg={props.setBackImg}
      />
      <div
        id="personal_info_modal"
        className="modal custom-modal fade "
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content" style={{ width: "615px" }}>
            <div className="modal-header">
              <h5 className="modal-title">
                {/* {" "}
                {applyLeave === "wfh"
                  ? "Apply for Work from home"
                  : applyLeave === "regular"
                  ? "Apply to Regularise Leave"
                  : applyLeave === "compoff"
                  ? "Apply for Comp off Leave"
                  : "Apply for Shift Change"} */}
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Select Manager <span className="text-danger">*</span>
                      </label>
                      {/* <select className="select form-control">
                          <option>Aman</option>
                          <option>Anuj</option>
                          <option>Jagdesh</option>
                        </select> */}
                      <Select />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>From</label>
                      <div>
                        <input className="form-control" type="date" />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>To</label>
                      <input className="form-control" type="date" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Reason </label>
                      <input className="form-control" type="lomg-text" />
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className=" btn-primary "
                    style={{
                      background: "#DF4E2F",
                      width: "94px",
                      height: "38px",
                      borderRadius: "5px",
                      fontSize: "16px",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {!(location.pathname === "/") ? (
        <SFab
          onClick={() => {
            setOpen1(!open1);
            setShowClock(false);
          }}
          color="primary"
          aria-label="add"
          data-bs-toggle="dropdown"
        >
          <img src={emoji} alt="" />
        </SFab>
      ) : null}

      {open1 ? (
        <SBox sx={{ boxShadow: 6 }}>
          <Box>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  p: 1,
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">Notifications</Typography>
                <Box sx={{ cursor: "pointer" }}>
                  <Typography
                    variant="h6"
                    onClick={() => {
                      setOpen1(!open1);
                      setShowClock(false);
                    }}
                  >
                    X
                  </Typography>
                </Box>
              </Box>

              <ScrollToBottom className="MessageContainer">
                <div className="Messages">
                  {notify1?.map(({ id, msg_id, messsage }) => {
                    return (
                      <div
                        key={id}
                        className={`Message ${
                          msg_id === 1 ? "sent" : "received"
                        }`}
                      >
                        <h5>{messsage ? messsage : "Hello!"}</h5>
                      </div>
                    );
                  })}
                </div>
              </ScrollToBottom>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 0.5,
                }}
              >
                <TextField
                  id="filled-size-small"
                  size="small"
                  placeholder="Message"
                  sx={{ width: "100%" }}
                />

                <IconButton sx={{ color: "#1f8ded" }}>
                  <SendIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </SBox>
      ) : null}
    </div>
  );
};

export default Header;
