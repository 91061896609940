// // src/components/Map.js
import React from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '500px'
};

// const locations = [
//   {
//     id: 1,
//     position: { lat: 19.0760, lng: 72.8777 },
//     content: 'Mumbai'
//   },
//   {
//     id: 2,
//     position: { lat: 28.7041, lng: 77.1025 },
//     content: 'Delhi'
//   },
//   {
//     id: 3,
//     position: { lat: 13.0827, lng: 80.2707 },
//     content: 'Chennai'
//   },
//   {
//     id: 4,
//     position: { lat: 22.5726, lng: 88.3639 },
//     content: 'Kolkata'
//   }
// ];

function Map({data}) {
  const [selected, setSelected] = React.useState(null);
  const locations = data.map((item, index) => ({
    id: index + 1,
    position: { lat: item.lat, lng: item.lng },
    content: item.emp_name
  })).slice(1, 20);
  console.log(locations)
  const middleIndex = Math.floor(data.length / 2);

// Get the middle element
const center= data[0];

// console.log(center); // Output: 30
// const center = {
//   lat: 19.1137207, lng: 72.8469973
// };



  
  return (
    <>
      {/* <iframe
  width="600"
  height="450"
  // style="border:0"
  loading="lazy"
  allowfullscreen
  referrerpolicy="no-referrer-when-downgrade"
  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyC-BtiuD2tU8x4DsHhWhsfhrWQDem2Ewz4
    &q=604-01, B Wing, HDIL Kaledonia, Sahar Rd, Andheri East, Mumbai, Maharashtra 400069">
</iframe> */}
  
    {/* <LoadScript googleMapsApiKey="AIzaSyC-BtiuD2tU8x4DsHhWhsfhrWQDem2Ewz4"> */}
  
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
      >
        {locations.map(location => (
          <Marker
            key={location.id}
            position={location.position}
            onClick={() => setSelected(location)}
          />
        ))}

        {selected && (
          <InfoWindow
            position={selected.position}
            onCloseClick={() => setSelected(null)}
          >
            <div>
              <h4>{selected.content}</h4>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    {/* </LoadScript> */}
    </>
  );
}

export default Map;
/********************************************************************************************************************** */

// import React from 'react';
// import { MapContainer, TileLayer, Polyline, Marker, Popup } from 'react-leaflet';

// import 'leaflet/dist/leaflet.css';
// // import { position } from 'html2canvas/dist/types/css/property-descriptors/position';

// const positions = [
//   [51.505, -0.09],
//   [51.51, -0.1],
//   [51.51, -0.12],
//   // Add more coordinates as needed
// ];

// const MyMapComponent = (props) => {
// //   console.log(props.data) 
// //  const  positions=props.data.map((ele)=>([ele.lat, ele.lng]))
// //  console.log(positions)

//   return (
//     <MapContainer center={positions[0]} zoom={13} style={{ height: "500px", width: "100%" }}>
//       <TileLayer
//         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//       />
      
//       {/* Plotting the route */}
//       <Polyline positions={positions} color="blue" />

//       {/* Adding markers */}
//       {positions.map((position, idx) => (
//         <Marker key={idx} position={position}>
//           <Popup>
//             Marker {idx + 1}
//           </Popup>
//         </Marker>
//       ))}
//     </MapContainer>
//   );
// };

// export default MyMapComponent;

/********************************************************************************************************************************** */
